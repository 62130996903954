.navButton {
  background-color: transparent;
  color: #FFFFFF;
  text-align: center;
  position: relative;
  margin: 0px 10px;
  font-size: 16px;
}


.navButton::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  height: 1px;
  background-color: #FFFFFF;
  transition:
    width 0.3s ease,
    left 0.3s ease;
}

.navButton:hover::after,
.navButton.active::after {
  width: 100%;
  left: 0;
  background: #FFFFFF;
  font-weight: bold;
}