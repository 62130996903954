.ui-drawer__close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2c405a;
}

.ui-drawer__close-btn a {
  padding-top: 3px;
}
