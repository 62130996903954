@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~@unite-us/ui/dist/main.css";
@import "~@unite-us/client-utils/dist/main.css";
@import "~@unite-us/app-components/dist/styles/tailwind.css";

* {
  font-family: "Proxima Nova", "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif";
}
html,
body,
#root {
  @apply h-full;
}
#root {
  @apply flex;
  @apply flex-col;
}

h1 {
  font-size: 20px;
}
h2 {
  font-size: 18px;
}